<template>
  <div id="change_pwd_container" :class="isHomePage ? 'homePage' : ''">
    <div class="change_pwd_content">
      <el-form
        label-position="right"
        ref="changePwdForm"
        label-width="80px"
        :model="userInfo"
        :rules="rules"
      >
        <el-form-item label="账号">
          <el-input v-model="userInfo.userName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="原始密码" prop="oldPwd">
          <el-input
            type="password"
            autocomplete="new-password"
            v-model="userInfo.oldPwd"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd">
          <el-input
            type="password"
            autocomplete="new-password"
            v-model="userInfo.newPwd"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPwd">
          <el-input
            type="password"
            autocomplete="new-password"
            v-model="userInfo.confirmPwd"
          ></el-input>
        </el-form-item>
        <el-button type="primary" @click="submitForm('changePwdForm')"
          >确认</el-button
        >
      </el-form>
    </div>
  </div>
</template>

<script>
import aesutil from "@/utils/AESUtil.js";
export default {
  props: {
    parameter: {
      type: Object,
      default: {}
    },
    isHomePage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      userInfo: {
        userName: "",
        oldPwd: "",
        newPwd: "",
        confirmPwd: ""
      },
      rules: {
        oldPwd: [
          { required: true, message: "请输入原始密码", trigger: "blur" }
        ],
        newPwd: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*[1-9])(?=.*[\W]).{6,18}$/,
            message:
              "请输入包含大小写字母、数字、以及特殊字符且最小6位最大18位的密码",
            trigger: "blur"
          }
        ],
        confirmPwd: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*[1-9])(?=.*[\W]).{6,18}$/,
            message:
              "请输入包含大小写字母、数字、以及特殊字符且最小6位最大18位的密码",
            trigger: "blur"
          }
        ]
      }
    };
  },
  watch: {
    parameter: {
      handler() {
        console.log(777, this.parameter);
        this.userInfo.userName = this.parameter.userName + "";
        this.userInfo.oldPwd = "";
        this.userInfo.newPwd = "";
        this.userInfo.confirmPwd = "";
      },
      immediate: true
    }
  },
  methods: {
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log(88, this.userInfo.oldPwd === this.userInfo.newPwd);
          if (this.userInfo.oldPwd === this.userInfo.newPwd) {
            this.$message({
              message: "新密码不能和旧密码相同！",
              type: "error"
            });
            return;
          }
          if (this.userInfo.newPwd !== this.userInfo.confirmPwd) {
            this.$message({
              message: "新密码和确认密码输入不相同！",
              type: "error"
            });
            return;
          }
          //  加密密码
          const oldPwd = aesutil.encrypt(this.userInfo.oldPwd);
          const newPwd = aesutil.encrypt(this.userInfo.newPwd);
          const params = {
            oldUserPwd: oldPwd,
            newUserPwd: newPwd,
            userAccount: this.userInfo.userName
          };
          this.$api("common.updatePwd")
            .invoke(params)
            .then(({ data: { code, data } }) => {
              // code为 1修改成功，为0修改失败
              if (code) {
                this.$message({
                  message: "密码修改成功",
                  type: "success"
                });
                sessionStorage.clear();
                localStorage.clear();
                let that = this;
                let routeData = that.$router.resolve({
                  path: "/"
                });
                window.open(routeData.href, "_self");
              } else {
                this.$message({
                  message: data.msg,
                  type: "error"
                });
              }
            })
            .catch(err => {
              this.$message({
                message: "修改失败" + err,
                type: "error"
              });
            });
        } else {
          that.$message({
            showClose: true,
            message: "数据填写有误，请检查！",
            type: "error",
            duration: 1000
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
#change_pwd_container {
  background: #f0f3fa;
  padding: 12px;
  .change_pwd_content {
    background: #fff;
    padding: 35px 10px 50px;
  }
  .el-form {
    /deep/ .el-button {
      color: #fff;
      background-color: #409eff;
      border-color: #409eff;
      margin-left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
.homePage {
  background: #041656 !important;
  .change_pwd_content {
    background: #030a38 !important;
    /deep/ .el-form-item__label {
      color: #fff !important;
    }
    /deep/ .el-input__inner {
      border-color: #2261d5 !important;
      background-color: #030a38 !important;
      color: #fff !important;
    }
    /deep/ .el-input.is-disabled .el-input__inner {
      color: #c0c4cc !important;
    }
    /deep/ .el-button {
      background-color: transparent !important;
      border-color: #2261d5 !important;
    }
  }
}
</style>
>
