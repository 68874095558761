<!--主框架-->
<template>
  <div id="index" @click="indexClickFun" v-loading="vLoading">
    <div id="head">
      <div class="head_box clear">
        <div class="head_left" ref="head_left">
          <!--系统名称-->
          <p class="head_left_txt">
            <span class="iconfont iconshui"></span>
            <span
              v-show="appTextP"
              class="header-title"
              :title="topTxt"
              style="font-size: 22px"
              >{{ systemConfig.VUE_APP_SYSTEM_TITLE_NAME }}</span
            >
            <span
              class="menuZD iconfont iconshousuo"
              :title="menuBtnT"
              :class="menuZDP ? 'active' : ''"
              @click="menuZDClickFun"
            ></span>
          </p>
        </div>
        <div class="head_right" @click.stop>
          <div class="head_right_box">
            <!--折叠按钮-->
            <!--菜单-->
            <div class="menuBox">
              <!--菜单-->
              <div id="menuNavNew" ref="menuNav">
                <ul class="clear" style="z-index: 99">
                  <!-- { active: item.sysmodel.includes(activeModel) } -->
                  <li
                    v-for="(item, index) in topMenuDataNew"
                    :key="index"
                    @click.stop="
                      !item.navigateUrl ? '' : menuClickFun(item, index)
                    "
                    :class="[
                      { active: item.active },
                      { iconshouyenew: item.isShowye },
                      'refLi' + index
                    ]"
                    style="line-height: 69px; z-index: 200; position: relative"
                    class="one"
                    :ref="'refLi' + index"
                    @mouseover="mouseOver(item, index)"
                  >
                    <div>
                      <div>
                        <span class="iconfont" :class="item.icon"></span>
                        <span style="font-size: 18px">{{ item.menuName }}</span>
                        <span class="dian" v-show="item.dianP"></span>
                      </div>
                      <!-- style="display: none" -->
                      <div
                        class="panels"
                        style="display: none"
                        :style="
                          'left:' +
                          item.leftVal +
                          'px;min-width:' +
                          item.width +
                          'px'
                        "
                      >
                        <div class="p-t-10"></div>
                        <ul
                          v-if="item.children && !item.navigateUrl"
                          class="ul"
                        >
                          <li
                            class="sencondMenuLi lis"
                            v-for="(item1, index1) in item.children"
                            :class="{ active: item1.sysmodel === activeModel }"
                            :key="index1"
                          >
                            <p class="titleDiv">{{ item1.menuName }}</p>
                            <div
                              v-if="item1.children && item1.children.length > 0"
                            >
                              <ul>
                                <li
                                  v-for="(lis, in1) in item1.children"
                                  class="lis"
                                  :class="{ fontColor: lis.class }"
                                  @click.stop="
                                    sencondMenuClick(lis, in1, index1, index)
                                  "
                                >
                                  {{ lis.menuName }}
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="moreBtn one" v-if="moreBtn">
                    ...
                    <div class="mornDiv">
                      <div class="p-t-10"></div>
                      <div class="mornDivSon">
                        <div v-for="li in moreList" class="divs">
                          <p
                            class="oneLevel"
                            @click.stop="oneLevelClick(li)"
                            :class="[
                              { fontColor: li.sysmodel.includes(activeModel) }
                            ]"
                          >
                            <span
                              class="iconfont iconSpan"
                              :class="li.icon"
                            ></span>
                            {{ li.menuName }}
                          </p>
                          <div class="two" :class="[{ twoLeft: left }]">
                            <ul
                              v-if="li.children && !li.navigateUrl"
                              class="twoUl"
                            >
                              <li
                                class="twoUlli"
                                v-for="item in li.children"
                                @click.stop="twoLevelClick(item)"
                              >
                                <p class="twoTitle">{{ item.menuName }}</p>
                                <div
                                  class="leafSon"
                                  v-if="
                                    item.children && item.children.length > 0
                                  "
                                >
                                  <ul>
                                    <li
                                      v-for="myli in item.children"
                                      @click.stop="threeLevelClick(myli)"
                                      :class="{ fontColor: myli.class }"
                                    >
                                      {{ myli.menuName }}
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!--用户-退出-->
            <div class="out_box" ref="out_box">
              <div class="out_box_nav clear">
                <!-- <div class="messDiv"> -->
                <!-- @click.native.stop="$router.push({ name: 'messageIndex' })" -->
                <!-- <el-badge
                    :value="noread"
                    :hidden="noread === 0"
                    :max="99"
                    class="item"
                    style="line-height: 24px; margin-right: 15px"
                  >
                    <i class="el-icon-message" style="font-size: 25px"></i>
                  </el-badge>
                </div> -->
                <div style="display: inline" @click.stop="outXLFun">
                  <div class="user_img"></div>
                  <div class="user_name">
                    {{ userName }}
                  </div>
                  <div class="app_out el-icon-caret-right"></div>
                  <!--下拉框-->
                  <transition name="el-zoom-in-top">
                    <div class="out_box_l" v-show="isOut" @click.stop>
                      <div class="transition-box">
                        <ul>
                          <!-- <li @click="backFun" v-if="usertype === '0'">
                            <span class="iconfont iconfanhui"></span>返回首页
                          </li> -->
                          <li @click="changePwd" v-if="isShow">
                            <span class="iconfont icontuichu1"></span>修改密码
                          </li>
                          <li @click="outClickFun">
                            <span class="iconfont icontuichu1"></span>退出登录
                          </li>
                        </ul>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="main">
      <keep-alive :include="homeCache">
        <router-view />
      </keep-alive>
    </div>
    <div class="foot">
      <p>{{ banquan }}&nbsp;&nbsp;&nbsp;&nbsp;{{ dianhua }}</p>
      <!-- 研究院用下面的 -->
      <!-- <p>技术支持单位：中国环境科学研究院</p> -->
    </div>
    <!--错误提示-->
    <!-- <ModuleError :errorData="errorData"></ModuleError> -->
    <!-- <Examine /> -->
    <el-dialog title="消息管理" :visible.sync="visible" width="60%">
      <el-scrollbar>
        <!-- <MessageIndex @getNoRead="getNoRead" v-if="visible" /> -->
      </el-scrollbar>
    </el-dialog>
    <el-dialog title="修改密码" :visible.sync="dialogVisibles" width="50%">
      <changePwd :parameter="parameter"></changePwd>
    </el-dialog>
    <el-dialog
      :title="titles"
      :visible.sync="dialogAccount"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeDialog"
    >
      <component
        :is="myComponent"
        :parameterAccount="parameterAccount"
      ></component>
    </el-dialog>
  </div>
</template>

<script>
import ModuleError from "../common/module/ModuleError";
import changePwd from "./changePwd";
import { mapState } from "vuex";
import { configMap } from "@/config/systemConfig";
import aesutil from "@/utils/AESUtil";
import { getFindIndex } from "@/utils/index.js";
export default {
  name: "index",
  // components: {ModuleError, MessageIndex, Examine},
  components: { ModuleError, changePwd },
  data() {
    return {
      vLoading: false,
      homeCache: [],
      visible: false,
      /*系统信息*/
      appText: "综合管理系统",
      appTextP: true,
      /*折叠按钮*/
      menuZDP: false,
      menuBtnT: "折叠",
      /*用户信息*/
      userName: "",
      /*菜单*/
      topMenuData: [],
      topMenuDataNew: [],
      moreList: [], //更多的菜单
      moveBtnP: false,
      menuMoveP: false,
      /*socket*/
      socket: "",
      sessionKey: "",
      appId: "",
      /*退出弹框*/
      isOut: false,
      /*错误组件*/
      errorData: {
        isError: false,
        showImg: true,
        errorType: "",
        expires: "",
        time: new Date().getTime()
      },
      topTxt: "企业碳排放账户",
      loginData: {},
      iconTitle: "",
      activeModel: "",
      activeModule: "",
      noread: 0,
      parameter: {},
      dialogVisibles: false,
      moreBtn: false,
      left: false,
      dafengImg: false,
      typeVal: 1,
      dialogAccount: false,
      parameterAccount: {},
      myComponent: "",
      titles: "",
      systemName: "",
      banquan: "",
      dianhua: "",
      systemConfig: {},
      isShow: true
    };
  },
  computed: {
    ...mapState({
      yjTopMenuData: state => state.menus.yjTopMenuData,
      usertype: state => state.user.usertype
    })
  },
  watch: {
    "$route.query.sysmodel"(value) {
      if (value) {
        this.activeModel = value;
        sessionStorage.setItem("value_sysmodel", value);
      }
    },
    "$route.query.moduleName"(value) {
      if (value) {
        this.activeModule = value;
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    const signOutRouteName = sessionStorage.getItem("signOutRouteName");
    if (!signOutRouteName) {
      sessionStorage.setItem("signOutRouteName", from.path);
    }
    next();
  },
  async created() {
    const isOuter = sessionStorage.getItem("isOuter");
    if (isOuter) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
    const systemName = sessionStorage.getItem("systemName") || "default";
    this.typeVal = sessionStorage.getItem("usertype");
    // this.systemName = this.VUE_APP_IMGNAME;
    console.log(this.typeVal);
    this.systemConfig = configMap.get(systemName);
    this.banquan = this.systemConfig.VUE_APP_BANQUAN;
    this.dianhua = this.systemConfig.VUE_APP_DIANHUA;
    document.title = this.systemConfig.VUE_APP_SYSTEM_TITLE_NAME;
    let that = this;
    // 免登录请求菜单
    const yjTopMenuData = this.$store.state.menus.yjTopMenuData;
    console.log("yjTopMenuData", this.$store.state.menus.yjTopMenuData);

    // console.log(this.$route.query)
    this.activeModule = this.$route.query.moduleName;
    // console.log("activeModule-----" + this.activeModule);
    this.activeModel =
      this.$route.query.sysmodel || sessionStorage.getItem("value_sysmodel");
    // console.log("activeModel-----" + this.activeModel);
    this.$nextTick(() => {
      that.topMenuData = JSON.parse(JSON.stringify(that.yjTopMenuData));
      this.treeSetAttr(this.topMenuData);
      this.topMenuData.map(e => {
        e.leftVal = 0;
        e.width = 0;
        e.navWidth = 0;
        if (e.icon == "icon-shouye") {
          e.isShowye = true;
        }
        if (e.menuName.length > 5) {
          //144是最大5个字，超出5个字计算菜单宽度有问题
          e.navWidth = (e.menuName.length - 5) * 18;
        }
        if (e.children && e.children.length > 0) {
          // e.width = (e.children.length - 1) * (154 + 25) + 154 + 10;
          e.width = (e.children.length - 1) * (154 + 15) + 154;
          e.children.map(q => {});
        } else {
          // if (e.sysmodel == that.$route.query.sysmodel) {
          //   e.active = true;
          // }
        }
      });
      that.menuCountFun(1);
    });

    sessionStorage.removeItem("menuZDP");
    /*获取传递的数据(sessionkey,appId)*/
    if (that.$route.query.sessionkey) {
      sessionStorage.setItem("sessionKey", that.$route.query.sessionkey);
    }
    if (that.$route.query.appId) {
      sessionStorage.setItem("appId", that.$route.query.appId);
    }
    /*sessionKey*/
    if (sessionStorage.getItem("sessionKey")) {
      that.sessionKey = sessionStorage.getItem("sessionKey");
    } else {
      that.sessionKey = "";
    }
    /*appId*/
    if (sessionStorage.getItem("appId")) {
      that.appId = sessionStorage.getItem("appId");
    } else {
      that.appId = "";
    }

    if (that.$route.query.sessionKey) {
      sessionStorage.setItem("sessionKey", that.$route.query.sessionKey);
    }
    that.sessionKey = sessionStorage.getItem("sessionKey");
    that.appId = "app2";
    that.userName = sessionStorage.getItem("loginUser");

    that.userInfoFun();
    that.getsystemparameter();

    this.$store.dispatch("findPageOperateByUserName");
  },
  methods: {
    jisuanCaidan() {
      let urls = window.location.href;
      let url = urls.split("#")[1].split("?")[0];
      let a = getFindIndex(
        this.$store.state.menus.yjTopMenuData,
        url,
        "navigateUrl"
      );
      let b = [];
      if (a.length > 0) {
        if (a.length == 1) {
          sessionStorage.setItem("navNum3", a[0]);
          sessionStorage.removeItem("navNum2");
          sessionStorage.removeItem("navNum1");
        } else {
          sessionStorage.setItem("navNum3", a[0]);
          sessionStorage.setItem("navNum2", a[1]);
          sessionStorage.setItem("navNum1", a[2]);
        }
      } else if (a.length == 0) {
        b = getFindIndex(this.moreList, this.rightDivActive, "moduleid");
        if (b.length == 1) {
          sessionStorage.setItem("navNum3", this.topMenuDataNew.length + b[0]);
          sessionStorage.removeItem("navNum2");
          sessionStorage.removeItem("navNum1");
          this.moreList[b[0]].active = true;
        } else if (b.length > 1) {
          sessionStorage.setItem("navNum3", this.topMenuDataNew.length + b[0]);
          sessionStorage.setItem("navNum2", b[1]);
          sessionStorage.setItem("navNum1", b[2]);
        } else {
          //不在菜单里
        }
      }
      // console.log(url);
      // console.log(a);
    },
    // 设置基本信息左侧菜单
    getBaseFillForm(params) {
      return new Promise(reslove => {
        this.$api("common.getBaseFillForm")
          .invoke(params)
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
              if (data) {
                let arr = [];
                let obj = {};
                data.map((e, i) => {
                  obj = {
                    children: [],
                    icon: "iconyuanquan",
                    // menuid: "a" + (i + 2),
                    menuid: e.pkId,
                    menuname: e.menuname,
                    menutitle: e.menuname,
                    navigateurl: e.formurl,
                    sysmodel: "baseInfo"
                  };
                  arr.push(obj);
                });
                this.$store.commit("updateMenus", { data: arr });
              }
            } else {
            }
          })
          .catch(() => {});
      });
    },
    getEntMenuList(val) {
      return new Promise(reslove => {
        this.$api("common.getEntMenuList")
          .invoke({ fkEntid: val })
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
              if (data) {
                let arr = [];
                let obj = {};
                data.map((e, i) => {
                  obj = {
                    children: [],
                    icon: "iconyuanquan",
                    // menuid: "q" + (i + 1),
                    menuid: e.pkId,
                    menuname: e.menuname,
                    menutitle: e.menuname,
                    navigateurl: e.formurl,
                    sysmodel: "qiangdufenxi"
                  };
                  arr.push(obj);
                });
                this.$store.commit("updateQdMenus", {
                  data: arr,
                  tongjiId: this.VUE_APP_TONGJIID
                });
              }
            } else {
            }
          })
          .catch(() => {});
      });
    },
    getEntLyEnumList(val) {
      return new Promise(reslove => {
        this.$api("common.getEntLyEnumList")
          .invoke({ fkEntid: val })
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
              if (data) {
                let arr = [];
                let obj = {};
                data.map((e, i) => {
                  obj = {
                    children: [],
                    icon: "iconyuanquan",
                    // menuid: "q" + (i + 1),
                    menuid: e.pkId,
                    menuname: e.menuname,
                    menutitle: e.menuname,
                    navigateurl: e.formurl,
                    sysmodel: "qiangdufenxi"
                  };
                  arr.push(obj);
                });
                this.$store.commit("updateShebeiQdMenus", {
                  data: arr,
                  tongjiId: this.VUE_APP_TONGJIID
                });
              }
            } else {
            }
          })
          .catch(() => {});
      });
    },
    getMenuFormList(val) {
      return new Promise(reslove => {
        this.$api("common.getMenuFormList")
          .invoke({ fkEntid: val, typecode: 1 })
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
              if (data) {
                console.log(data);
                let arr = [];
                let obj = {};
                data.map((e, i) => {
                  obj = {
                    // children: [],
                    icon: "",
                    // menuid: "q" + (i + 1),
                    moduleid: e.pkId,
                    menuName: e.menuname,
                    menutitle: e.menuname,
                    navigateUrl: e.formurl,
                    sysmodel: "",
                    URLTYPE: 0
                  };
                  arr.push(obj);
                });
                this.$store.commit("updateTongjiTopMenus", {
                  data: arr,
                  tongjiId: this.VUE_APP_TONGJIID
                });
              }
            } else {
            }
          })
          .catch(() => {});
      });
    },
    menuStyle(val) {
      // console.log(val);
      // console.log(this.topMenuData);
      $("#menuNavNew > ul > li").removeClass("active");
      this.topMenuData.map((e, inx) => {
        // e.active = false;
        if (e.children && e.children.length > 0) {
          e.children.map(q => {
            if (q.children && q.children.length > 0) {
              q.children.map(a => {
                a.class = false;
                if (val && a.moduleid == val) {
                  a.class = true;
                  // e.active = true;
                  const index = this.topMenuData
                    .map(item => item.moduleid)
                    .indexOf(a.rootModuleid);
                  $("#menuNavNew > ul > li").removeClass("active");
                  $("#menuNavNew > ul > li").removeClass("active");
                  $("#menuNavNew > ul > li").eq(index).addClass("active");
                  $("#menuNavNew > ul > li").eq(index).addClass("active");
                }
              });
            }
          });
        }
      });
    },
    styleSet(index) {
      sessionStorage.removeItem("navNum1");
      sessionStorage.removeItem("navNum2");
      sessionStorage.setItem("navNum3", index);
      this.topMenuDataNew.map((e, inx) => {
        e.active = false;
        if (inx == index) {
          e.active = true;
          sessionStorage.setItem("menuName", e.menuName);
        }
        if (e.children && e.children.length > 0) {
          e.children.map(q => {
            if (q.children && q.children.length > 0) {
              q.children.map(a => {
                a.class = false;
              });
            }
          });
        }
      });
      this.moreList.map(e => {
        e.active = false;
        if (e.children && e.children.length > 0) {
          e.children.map(q => {
            if (q.children && q.children.length > 0) {
              q.children.map(a => {
                a.class = false;
              });
            }
          });
        }
      });
    },
    menuStyleNew(val1, val2, val3) {
      sessionStorage.setItem("navNum1", val1);
      sessionStorage.setItem("navNum2", val2);
      sessionStorage.setItem("navNum3", val3);
      // console.log(this.topMenuDataNew);
      this.topMenuDataNew.map((e, inx) => {
        e.active = false;
        if (inx == val3) {
          e.active = true;
          sessionStorage.setItem("menuName", e.menuName);
        }
        if (e.children && e.children.length > 0) {
          e.children.map(q => {
            if (q.children && q.children.length > 0) {
              q.children.map(a => {
                a.class = false;
              });
            }
          });
        }
      });
      if (val3 <= this.topMenuDataNew.length - 1) {
        if (
          this.topMenuDataNew[val3].children &&
          this.topMenuDataNew[val3].children.length > 0
        ) {
          if (
            this.topMenuDataNew[val3].children[val2].children &&
            this.topMenuDataNew[val3].children[val2].children.length > 0
          ) {
            this.topMenuDataNew[val3].children[val2].children[
              val1
            ].class = true;
          }
        } else {
          this.topMenuDataNew[val3].active = true;
        }
      } else {
        let a = val3 - this.topMenuDataNew.length;
        this.moreList.map((e, inx) => {
          e.active = false;
          if (inx == a) {
            e.active = true;
            sessionStorage.setItem("menuName", e.menuName);
          }
          if (e.children && e.children.length > 0) {
            e.children.map(q => {
              if (q.children && q.children.length > 0) {
                q.children.map(a => {
                  a.class = false;
                });
              }
            });
          }
        });
        //console.log(this.moreList[a]);
        if (this.moreList[a].children && this.moreList[a].children.length > 0) {
          if (
            this.moreList[a].children[val2].children &&
            this.moreList[a].children[val2].children.length > 0
          ) {
            this.moreList[a].children[val2].children[val1].class = true;
          }
        }
      }
    },
    // 子菜单关联一级菜单moduleid属性
    treeSetAttr(menus, moduleid) {
      menus.forEach(data => {
        if (moduleid) {
          data.rootModuleid = moduleid;
        }
        if (data.children && data.children.length) {
          this.treeSetAttr(data.children, data.rootModuleid || data.moduleid);
        }
      });
    },
    menuClickCount(val) {
      //菜单点击访问量
      let censusObj = {
        systemcode: this.VUE_APP_CODE, //访问系统代码
        systemname: this.VUE_APP_NAME, //访问系统名称
        menuid: val.moduleid,
        menuname: val.menuName,
        visittime: +new Date(),
        visittype: "menu"
      };
      if (this.dafengImg) {
        censusObj.systemcode = this.VUE_APP_DF_CODE;
        censusObj.systemname = this.VUE_APP_DF_NAME;
      }
    },
    getUrlParams(url) {
      let urlStr = url.split("?")[1];
      let obj = {};
      let paramsArr = urlStr.split("&");
      for (let i = 0, len = paramsArr.length; i < len; i++) {
        let arr = paramsArr[i].split("=");
        obj[arr[0]] = arr[1];
      }
      return obj;
    },
    async jumpJudgment(val, cengji) {
      let result = true;
      if (val.URLTYPE && (val.URLTYPE == "3" || val.URLTYPE == 3)) {
        if (cengji) {
          this.menuStyleNew(cengji.index2, cengji.indexTop, cengji.index);
        }
        let source = "tan";
        // const token = aesutil.encrypt(sessionStorage.getItem("token"));
        const token = sessionStorage.getItem("token");
        let urls = val.navigateUrl.replace(/\${token}/g, token);
        let canshu = this.getUrlParams(urls);
        if (canshu && canshu.source) {
          source = canshu.source;
        }
        sessionStorage.setItem("url", urls);
        await this.$store.dispatch("getCacheUser");
        if (sessionStorage.getItem("qiantao") == "1") {
          sessionStorage.setItem("qiantao", "2");
          this.$router.push({
            path: "/index/iframesT",
            query: {
              sysmodel: val.sysmodel,
              moduleName: val.moduleName,
              moduleid: val.moduleid,
              source: source
            }
          });
        } else {
          sessionStorage.setItem("qiantao", "1");
          this.$router.push({
            path: "/index/iframes",
            query: {
              sysmodel: val.sysmodel,
              moduleName: val.moduleName,
              moduleid: val.moduleid,
              source: source
            }
          });
        }
      } else if (val.URLTYPE && (val.URLTYPE == "2" || val.URLTYPE == 2)) {
        //新窗口打开
        const token = aesutil.encrypt(sessionStorage.getItem("token"));
        let urls = val.navigateUrl.replace(/\${token}/g, token);
        window.open(urls, "_blank");
      } else if (val.URLTYPE && (val.URLTYPE == "4" || val.URLTYPE == 4)) {
        let arr = window.location.hostname.split(".");
        let str = arr[0] + arr[1];
        let url = "";
        let urlArr = val.navigateUrl.split(";");
        if (str === "17216" || str === "192168") {
          //内网
          url = urlArr[0];
        } else {
          //外网
          url = urlArr[1];
        }
        const token = aesutil.encrypt(sessionStorage.getItem("token"));
        url = url.replace(/\${token}/g, token);
        window.open(url, "_blank");
      } else {
        result = false;
      }
      return result;
    },
    async oneLevelClick(val) {
      //更多菜单的一级菜单点击
      // console.log(val);
      if (val.children && val.children.length > 0 && !val.navigateUrl) {
        //没有左侧菜单，只有子集页面
        return false;
      }
      this.menuClickCount(val);
      let a = await this.jumpJudgment(val);
      if (a == true) {
        return false;
      }
      if (val.navigateUrl) {
        this.menuStyle(val.moduleid);
        this.$router.push({
          path: val.navigateUrl,
          query: {
            sysmodel: val.sysmodel,
            moduleName: val.moduleName,
            moduleid: val.moduleid
          }
        });
      }
    },
    twoLevelClick(val) {
      //更多菜单的 二级菜单点击
      // console.log(val);
      // return false;
      if (!val.navigateUrl && val.children) {
        // console.log("有子集菜单");
        return false;
      }

      this.menuClickCount(val);

      if (val.navigateUrl && !val.children) {
        this.menuStyle(val.moduleid);
        //直接跳转
        this.$router.push({
          path: val.navigateUrl,
          query: {
            sysmodel: val.sysmodel,
            moduleName: val.moduleName,
            moduleid: val.moduleid
          }
        });
      }

      if (val.navigateUrl.indexOf("leftMenuIndex") > -1) {
        this.menuStyle(val.moduleid);
        //跳转到左侧菜单
        this.$router.push({
          path: val.navigateUrl,
          query: {
            sysmodel: val.sysmodel,
            moduleName: val.moduleName,
            moduleid: val.moduleid
          }
        });
      }
    },
    async threeLevelClick(val) {
      this.menuClickCount(val);
      //
      this.menuStyle(val.moduleid);
      let a = await this.jumpJudgment(val);
      if (a == true) {
        return false;
      }
      this.$router.push({
        path: val.navigateUrl,
        query: {
          sysmodel: val.sysmodel,
          moduleName: val.moduleName,
          moduleid: val.moduleid
        }
      });
    },
    async sencondMenuClick(item, index2, indexTop, index) {
      // console.log(item);
      this.menuClickCount(item);
      //
      let a = await this.jumpJudgment(item, {
        index2: index2,
        indexTop: indexTop,
        index: index
      });
      if (a == true) {
        return false;
      }
      let that = this;
      this.showModel();
      // this.$store.commit('RECOVERY_ID')
      let {
        isFromBackStage,
        moduleName,
        menuId,
        sysmodel,
        navigateUrl,
        target
      } = item;
      if (item.navigateUrl) {
        let { navigateUrl, menuId, moduleName, sysmodel } = item;
        if (navigateUrl.indexOf("leftMenuIndex") !== -1) {
          this.menuStyleNew(index2, indexTop, index);
          this.$router.push({
            path: item.navigateUrl,
            query: {
              sysmodel: item.sysmodel,
              moduleName: item.moduleName,
              moduleid: item.moduleid,
              suiji: new Date().getTime()
            }
          });
        } else {
          this.menuStyleNew(index2, indexTop, index);
          this.$router.push({
            path: item.navigateUrl,
            query: {
              sysmodel: item.sysmodel,
              moduleName: item.moduleName,
              moduleid: item.moduleid
            }
          });
        }
      } else {
        that.$router.push({
          path: "/index/404"
        });
      }
      sessionStorage.setItem("topMenuInd", index);
      // sessionStorage.setItem('isFromBackStage', isFromBackStage)
      sessionStorage.setItem("moduleName", moduleName);
      this.menuMoveP = false;
      this.BUS.$emit("removeHomeCache", "combatMap");
    },
    /*整体点击事件*/
    indexClickFun() {
      let that = this;
      that.menuMoveP = false;
    },
    /*更多菜单点击事件*/
    moveBtnClickFun() {
      let that = this;
      that.menuMoveP = !that.menuMoveP;
    },
    showModel() {
      this.vLoading = true;
      setTimeout(() => {
        this.vLoading = false;
      }, 200);
    },
    /*菜单的点击事件*/
    async menuClickFun(item, index) {
      this.menuClickCount(item);

      let a = await this.jumpJudgment(item);
      if (a == true) {
        return false;
      }
      let that = this;
      this.showModel();
      sessionStorage.setItem("topMenuInd", index);
      sessionStorage.removeItem("showBack");
      /*清空跳转页面的缓存*/
      sessionStorage.removeItem("tabData");
      sessionStorage.removeItem("menuActive");
      that.menuMoveP = false;
      /*删除滚动条*/
      // $(".nicescroll-rails").remove();
      /*跳转页面*/
      if (item.navigateUrl) {
        that.styleSet(index);
        // this.menuStyle(item.moduleid);
        let { navigateUrl, menuId, moduleName, sysmodel } = item;
        that.$router.push({
          path: item.navigateUrl,
          query: {
            sysmodel: item.sysmodel,
            moduleName: item.moduleName,
            menuClick: true
          }
        });
        // }
      } else {
        that.$router.push({
          path: "/index/404"
        });
      }
    },
    closeDialog() {
      this.dialogAccount = false;
      this.myComponent = "";
    },
    /*退出点击事件*/
    outClickFun() {
      let that = this;
      that
        .$confirm("是否退出当前系统？", "退出提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          /*关闭socket*/
          // that.socket.disconnect();
          /*删除所有的缓存*/
          that.outFun();
          sessionStorage.clear();
          localStorage.clear();
          // that.dispatch()
        });
    },
    mouseOver(item, index) {
      let head_left = this.$refs.head_left.clientWidth;
      let lefts = this.$refs["refLi" + index][0].offsetLeft;
      // console.log(this.$refs["refLi" + index]);
      let juli = document.body.clientWidth - head_left - lefts;
      // console.log("浏览器的宽度：" + document.body.clientWidth);
      // console.log("右侧距离：" + juli);
      if (item.width > juli) {
        this.topMenuData[index].leftVal = 0 - (item.width - juli) - 10;
      } else {
        this.topMenuData[index].leftVal = 0;
      }
      this.$forceUpdate();
      // console.log(this.$refs["refLi" + index].target.getBoundingClientRect());
    },
    /*计算菜单是否超出*/
    menuCountFun(val) {
      let that = this;
      that.$nextTick(function () {
        let allW = document.body.clientWidth;
        // console.log(allW);
        let lefts = this.$refs.head_left.clientWidth;
        let wCaidan = 0; //实际的菜单宽度
        that.left = false;
        setTimeout(function () {
          let rights = that.$refs.out_box.clientWidth;
          if (rights < 290 - 50) {
            that.left = true;
          }
          let isHaveShowye = false; //是否有首页
          wCaidan = 154 * (that.topMenuData.length - 1) + 90;
          // console.log(that.topMenuData);
          that.topMenuData.map(e => {
            if (e.navWidth > 0) {
              wCaidan = wCaidan + e.navWidth;
            }
            if (e.icon == "icon-shouye") {
              isHaveShowye = true;
            }
          });
          let c = allW - lefts - rights; //可视区域的宽度
          // console.log("浏览器的宽度" + allW);
          // console.log("可视区域的宽度浏览器的宽度" + c);
          // console.log("实际菜单的宽度" + wCaidan);
          that.topMenuDataNew = [];
          that.moreList = [];
          if (c < wCaidan) {
            //出现3个小点
            that.moreBtn = true;
            // console.log(c - 50 - 100);
            let nums = 0;
            // console.log(nums);
            if (isHaveShowye) {
              nums = parseInt((c - 50 - 100) / 154);
              nums = nums + 1;
            } else {
              nums = Math.floor((c - 50) / 154);
            }
            for (let i = 0; i < nums; i++) {
              that.topMenuDataNew.push(that.topMenuData[i]);
            }
            for (let i = nums; i < that.topMenuData.length; i++) {
              that.moreList.push(that.topMenuData[i]);
            }
            // console.log(that.topMenuDataNew);
            // that.menuZDP = false;
            // that.menuZDClickFun();
          } else {
            that.moreBtn = false;
            that.topMenuDataNew = that.topMenuData;
          }
          if (val) {
            // setTimeout(function () {
            that.jisuanCaidan();
            let a = sessionStorage.getItem("navNum1");
            let b = sessionStorage.getItem("navNum2");
            let c = sessionStorage.getItem("navNum3"); //第一级的索引
            if (b == undefined || b == "NaN" || b == "undefined") {
              that.styleSet(Number(c));
            } else {
              that.menuStyleNew(Number(a), Number(b), Number(c));
            }
            // }, 500);
            // console.log(that.topMenuData);
          }
          // console.log(that.moreList);
        }, 500);
      });
    },
    /*折叠点击事件*/
    menuZDClickFun() {
      let that = this;
      that.menuZDP = !that.menuZDP;
      /*true:折叠，false:没有折叠*/
      sessionStorage.setItem("menuZDP", that.menuZDP);
      /*传递折叠状态*/
      that.BUS.$emit("menuZDCFun", that.menuZDP);
      /*改变样式*/
      if (that.menuZDP) {
        that.appTextP = false;
        $("#head .head_left .header-title").animate(
          {
            width: "0px"
          },
          300,
          function () {
            that.menuBtnT = "展开";
            that.menuCountFun();
          }
        );
      } else {
        that.appTextP = true;
        $("#head .head_left .header-title").animate(
          {
            width: that.headLeftWidth
          },
          300,
          function () {
            that.menuBtnT = "折叠";
            that.menuCountFun();
          }
        );
      }
      console.log("appTextP:::::" + that.appTextP);
    },
    /*错误组件*/
    errorFun(isError, showImg, errorType, expires) {
      /*开关-是否图片展示错误-展示的类型-过期操作*/
      this.errorData.isError = isError;
      this.errorData.showImg = showImg;
      this.errorData.errorType = errorType;
      this.errorData.expires = expires;
      this.errorData.time = new Date().getTime();
    },
    /*用户信息*/
    async userInfoFun() {
      let that = this;
      let resObj = await this.$store.dispatch("getCacheUser");
      // await that.$store.dispatch('initEnterpriseInfo', res.entId)
      that.userName = resObj.username;
      sessionStorage["userid"] = resObj.userid;
      sessionStorage.setItem(
        "loginUser_ws",
        `${resObj.username}*${resObj.userid}`
      );
    },
    // 根据用户ID获取企业信息
    userEnterprise(id) {
      let that = this;
      let params = {
        userid: id
      };
      that.$http
        .get(
          that.VUE_APP_BASE_API +
            "/emergencyJX/basPollution/getPollutionByUserid",
          { params }
        )
        .then(async res => {
          let data = res.data;
          if (data.code == 1) {
            that.topTxt = data.data.pollutionname;
            that.$store.commit("updatePollutionname", data.data.pollutionname);
            that.$store.commit("updateLongitude", data.data.longitude);
            that.$store.commit("updateLatitude", data.data.latitude);
            that.$store.commit("updateState", true);

            // await that.$store.dispatch('initEnterpriseInfo', data.data.pkPollutionid)
            that.$store.commit("updatePkpollutionid", data.data.pkPollutionid);
          }
        });
    },
    /*退出*/
    outFun() {
      let routeData;
      const signOutRouteName = sessionStorage.getItem("signOutRouteName");
      routeData = this.$router.resolve({
        path: signOutRouteName
      });
      window.open(routeData.href, "_self");
      // $(".el-message-box__wrapper,.v-modal").remove();
    },
    outXLFun() {
      let that = this;
      that.isOut = !that.isOut;
    },
    /*返回*/
    backFun() {
      this.$router.push({
        name: "homePage"
      });
    },
    // 获取系统参数
    getsystemparameter() {
      let that = this;
      let params = {
        parametercode: "",
        parametername: "",
        remark: "",
        page: 0,
        rows: 0
      };
      that
        .$api("common.selectList")
        .invoke(params)
        .then(({ data: { data } }) => {
          that.$store.commit("mySystemData", data.data);
        });
    },
    // 修改密码
    changePwd() {
      this.dialogVisibles = true;
      this.isOut = false;
      this.parameter = {
        userName: this.$store.state.user.useraccount
      };
    }
  },
  mounted() {
    this.BUS.$on("sencondMenuClick", (...param) => {
      this.sencondMenuClick(...param);
    });

    this.BUS.$on("addHomeCache", val => {
      this.homeCache.push(val);
    });
    this.BUS.$on(
      "removeHomeCache",
      val => (this.homeCache = this.homeCache.filter(v => v !== val))
    );

    window.requestAnimationFrame(() => {
      this.headLeftWidth = $(".header-title").outerWidth();
      this.menuCountFun(1);
    });
    let that = this;
    /*计算菜单是否超出*/

    /*窗口的点击事件*/
    $(window).click(function () {
      that.menuMoveP = false;
      that.isOut = false;
    });
    /*窗口改变时触发的事件*/
    window.onresize = () => {
      that.menuCountFun();
    };
  }
};
</script>

<style lang="less" scoped>
#index {
  width: 100%;
  height: 100%;
  padding-top: 70px;
  position: relative;
}

#head {
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

#head .head_box {
  display: flex;
  width: 100%;
  height: 100%;
  // padding-left: 300px;
  // position: relative;
}

/*左侧*/
#head .head_left {
  height: 100%;
  // overflow: hidden;
  // position: absolute;
  top: 0;
  left: 0;
}

/*系统名*/
#head .head_left .head_left_txt {
  width: 100%;
  height: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  color: #ffffff;
  font-size: 24px;
  padding-left: 20px;
}

/*系统图标*/
.head_left .head_left_txt .iconfont {
  font-size: 26px;
  color: #ffffff;
  width: 28px;
  height: 28px;
  display: inline-block;
}
.head_left .head_left_txt .iconshui {
  background-image: url(../assets/img/logoicon.png);
  background-repeat: no-repeat;
}
/*右侧*/
#head .head_right {
  width: 100%;
  height: 100%;
}

#head .head_right .head_right_box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

/*折叠按钮*/
#head .head_box .head_left_txt .menuZD {
  // position: absolute;
  // top: 50%;
  // left: 14px;
  // margin-top: -11px;
  color: #e5fffd;
  font-size: 22px;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 5px;
}

#head .head_box .head_left_txt .menuZD.active {
  transform: rotateY(180deg);
}

#head .head_box .head_left_txt .menuZD:hover {
  font-weight: bold;
}

/*菜单-更多菜单*/
.head_right .head_right_box .menuBox {
  flex: 1;
  height: 100%;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*更多菜单按钮*/
.menuBox .moveBtn {
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  position: absolute;
  top: 35%;
  right: 0px;
  margin-top: -11px;
  z-index: 10;
}

.menuBox .moveBtn:hover,
.menuBox .moveBtn.active {
  font-weight: bold;
}

.header-title {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  // width: 195px;
  padding-left: 5px;
  // height: 25px;
  margin-left: 5px;
}
/*更多菜单*/
#menuMove {
  width: 100%;
  position: absolute;
  top: 70px;
  right: 0;
  z-index: 1000;
  /* border: 1px solid #eee; */
  box-shadow: 0 4px 6px #eee;
  border-top: none;
  background: #fff;
}

#menuMove ul {
  width: 100%;
  /* padding-bottom: 12px; */
  color: white;
}

#menuMove li {
  width: 100%;
  height: 45px;
  line-height: 45px;
  /* border-bottom: 1px solid #dddddd; */
  cursor: pointer;
  font-size: 16px;
  padding: 0px 12px;
}

#menuMove li ul li {
  color: #606266;
}

#menuMove li .iconfont {
  padding-right: 2px;
}

#menuMove li .dian {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #f3653a;
  border-radius: 50%;
}

/*用户名-退出*/
.head_right .head_right_box .out_box {
  height: 100%;
  padding: 12px 0;
  position: relative;
  cursor: pointer;
}

.out_box .out_box_nav {
  height: 100%;
  padding: 0 12px;
  min-width: 132px;
  /*border-left: 1px solid #3675bf;*/
  .messDiv {
    color: #fff;
    float: left;
    margin-top: 9px;
  }
}

/*头像*/
.out_box .user_img {
  float: left;
  width: 40px;
  height: 100%;
  background: url("../assets/img/user_img.png") no-repeat center;
}

/*用户名*/
.out_box .user_name {
  float: left;
  height: 100%;
  font-size: 16px;
  color: #fff;
  line-height: 46px;
  padding: 0 8px 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*退出*/
.out_box .app_out {
  float: right;
  color: #ffffff;
  font-size: 14px;
  height: 100%;
  line-height: 46px;
  transform: rotate(90deg);
}

/*退出下拉框*/
.out_box .out_box_l {
  width: 90%;
  position: absolute;
  top: 70px;
  z-index: 9999;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 0 12px;
  right: 12px;
  width: 120px;
  text-align: center;
}

.out_box .out_box_l li {
  line-height: 36px;
  border-bottom: 1px solid #dddddd;
  font-size: 16px;
}

.out_box .out_box_l li:last-child {
  border: none;
}

.out_box .out_box_l li:hover,
.out_box .out_box_l li:hover .iconfont {
  color: #00aaff;
}

.out_box .out_box_l li .iconfont {
  font-size: 16px;
  color: #333;
  margin-right: 8px;
}

/*主体内容*/
#main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // background: #f0f3fa;
  background: #fff;
  height: calc(100% - 24px);
  position: relative;
}
.sencondMenu {
  // border-radius: 5px;
  li {
    line-height: 35px;
    text-align: center;
    // border-top: 1px solid rgb(34, 123, 234);
    &:nth-of-type(1) {
      border-top: 1px solid #fff;
    }
    &:hover {
      // color: #1c65e0;
      // background: #e8f1fb;;
      // color: #62A8D1;
    }
  }
  > .active {
    // background: #e8f1fb !important;
    // color: #62A8D1;
  }
}
.foot {
  width: 100%;
  height: 24px;
  line-height: 24px;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // z-index: 1110;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 12px;
  text-align: center;
}
.myli:hover ul {
  display: block !important;
}
/*菜单*/
#menuNavNew {
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
  text-align: right;
}

#menuNavNew > ul > li {
  display: inline-block;
  height: 100%;
  line-height: 69px;
  font-size: 16px;
  color: #ffffff;
  // padding: 0 12px;
  min-width: 130px;
  min-width: 154px;
  text-align: center;
  text-align: left;
  cursor: pointer;
  position: relative;
  vertical-align: top;
}

#menuNavNew > ul > li:hover .panels {
  display: block !important;
}
#menuNavNew > ul > li .panels {
  // background: #fff;
  color: #333;
}
#menuNavNew > ul > li:hover > div > ul {
  display: block !important;
  // background: #1c65e0;
  color: #616161;
  background: #ffffff;
}

#menuNavNew > ul > li > div > span:nth-of-type(1) {
  margin-left: 10px;
}
#menuNavNew {
  .panels {
    position: absolute;
    .ul {
      background: #fff;
      border-radius: 4px;
      border: 1px solid #d1dbe5;
      overflow: hidden;
      padding: 5px;
      .sencondMenuLi {
        display: inline-block;
        width: 154px;
        float: left;
        padding-left: 10px;
        .titleDiv {
          line-height: 36px;
          text-align: left;
          border-bottom: 1px solid #e5e5e5;
          color: #999;
        }
        .lis {
          line-height: 36px;
          font-size: 14px;
        }
      }
    }
  }
}
#menuNavNew {
  ul {
    li {
      div {
        div {
          // padding-left: 26px;
          // text-align: left;
        }
        span.iconfont {
          width: 22px;
          height: 22px;
          display: inline-block;
          // position: absolute;
          // top: 22px;
          // top: 24px;
          // left: 0px;
          float: left;
          // margin-left: 10px;
          // margin-left: 24px;
          margin-right: 8px;
          margin-left: 14px;
        }
      }
    }
    li.one:first-child {
      // min-width: 100px;
    }
    li.one.iconshouyenew {
      min-width: 100px;
    }
    li.moreBtn {
      min-width: 50px;
      text-align: center;
      position: relative;
      z-index: 1000;
      .mornDiv {
        position: absolute;
        min-width: 154px;
        display: none;
        .mornDivSon {
          border: 1px solid #d1dbe5;
          background: #fff;
          border-radius: 4px;
          padding: 5px;
          .divs {
            position: relative;
            .oneLevel {
              text-align: left;
              border-bottom: 1px solid #e5e5e5;
              color: #999;
              height: 36px;
              line-height: 36px;
              .iconSpan {
                top: 8px;
                margin-right: 4px;
                margin-left: 4px;
              }
              // span.iconshouye {
              //   background: url("../assets/img/navGray/shouyenew.png") no-repeat
              //     center;
              // }
              // span.iconjibenxinxi {
              //   background: url("../assets/img/navGray/jbxx.png") no-repeat
              //     center;
              // }
              // span.icontanpaifangbaogao {
              //   top: 6px;
              //   background: url("../assets/img/navGray/tpfbg.png") no-repeat
              //     center;
              // }
              // span.iconshujutianbao {
              //   background: url("../assets/img/navGray/sjtb.png") no-repeat
              //     center;
              // }
              // span.iconshujuzhanshi {
              //   background: url("../assets/img/navGray/sjzs.png") no-repeat
              //     center;
              // }
              // span.icontanzichanguanli {
              //   background: url("../assets/img/navGray/tzcgl.png") no-repeat
              //     center;
              // }
              // span.icontongjifenxi {
              //   background: url("../assets/img/navGray/tjfx.png") no-repeat
              //     center;
              // }
              // span.iconzongheguanli {
              //   background: url("../assets/img/navGray/zhgl.png") no-repeat
              //     center;
              // }
              // span.iconshouyenew {
              //   background: url("../assets/img/navGray/shouyenew.png") no-repeat
              //     center;
              // }
              // span.iconzichanguanli {
              //   background: url("../assets/img/navGray/zhgl.png") no-repeat
              //     center;
              // }
              // span.iconzhishiku {
              //   background: url("../assets/img/navGray/zhishiku.png") no-repeat
              //     center;
              // }
              // span.iconfenpei {
              //   background: url("../assets/img/navGray/fenpei.png") no-repeat
              //     center;
              // }
              // span.iconchoucha {
              //   background: url("../assets/img/navGray/choucha.png") no-repeat
              //     center;
              // }
              // span.icontanzhanghu {
              //   background: url("../assets/img/navGray/tanzhanghu.png")
              //     no-repeat center;
              // }
              // span.icononline {
              //   background: url("../assets/img/navGray/online.png") no-repeat
              //     center;
              // }
              // span.iconqiyeliebiao {
              //   background: url("../assets/img/navGray/qiyeliebiao.png")
              //     no-repeat center;
              // }
              // span.iconshenhe {
              //   background: url("../assets/img/navGray/shujushenhe.png")
              //     no-repeat center;
              // }
            }
            .two {
              position: absolute;
              left: 132px;
              top: 0;
              color: #333;
              // background: pink;
              min-width: 154px;
              display: none;
              padding-right: 10px;
              padding-left: 10px;
              li {
                line-height: 36px;
              }

              .twoUl {
                background: #fff;
                border: 1px solid #e5e5e5;
                border-radius: 4px;
                padding-left: 10px;
                padding-right: 10px;
                .twoUlli {
                  .twoTitle {
                    height: 36px;
                    line-height: 36px;
                    color: #999;
                    text-align: left;
                    border-bottom: 1px solid #e5e5e5;
                  }
                  .leafSon {
                    font-size: 14px;
                    color: #333;
                    text-align: left;
                  }
                }
              }
            }
            .twoLeft {
              left: -154px;
              padding-left: 0;
            }
          }
          .divs:last-child {
            .oneLevel {
              border-bottom: none;
            }
          }
          .divs:hover {
            .two {
              display: block;
            }
          }
        }
      }
    }
    li.moreBtn:hover {
      .mornDiv {
        display: block;
      }
    }
  }
}
#menuNavNew > ul > li > div > span:nth-of-type(2) {
  margin-right: 10px;
  margin-left: 25px;
}

#menuNavNew > ul > li .dian {
  position: absolute;
  top: 4px;
  right: 0;
}

#menuNavNew > ul > li .iconfont {
  font-size: 24px;
  // position: relative;
  // top: 2px;
}
</style>
