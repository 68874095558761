// 转换数组
// [1,2,3] => [[1,2,3]]
// [1,2,3,4,5] => [[1,2],[3,4,5]]
// [1,2,3,4,5,6] => [[1,2],[3,4],[5,6]]
export function transArray(array) {
  let newArray = [];
  if (array.length < 4) {
    newArray.push(array);
  } else {
    for (let i = 0; i < array.length; i += 2) {
      if (array.length % 2 === 0) {
        newArray.push(array.slice(i, i + 2));
      } else {
        if (i === array.length - 3) {
          newArray.push(array.slice(i, i + 3));
          i += 2;
        } else {
          newArray.push(array.slice(i, i + 2));
        }
      }
    }
  }
  return newArray;
}
//根据字段递归，获取当前值的父级以及上级的索引
export function getFindIndex(arr, value, key = "id") {
  let result = [];
  function search(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][key] === value) {
        result.push(i);
        return true;
      }
      if (arr[i].children && arr[i].children.length > 0) {
        result.push(i);
        if (search(arr[i].children, value)) {
          return true;
        } else {
          result.pop();
        }
      }
    }
    return false;
  }
  search(arr, value);
  return result;
}
